import React from "react"
import SEO from "../components/global/Seo"
import Layout from "../components/layout/Layout"
import Services from "../components/home/Services"

export default function Oferta() {
  return (
    <Layout breadcrumbs={[{ title: "Oferta", url: "/oferta" }]}>
      <SEO title="Oferta" />
      <Services showTitle={false} />
    </Layout>
  )
}
